@import '~antd/dist/antd.less';

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}
.layout-content {
  padding: 0 50px;
  margin-top: 64px;
  .layout-content-inner {
    padding: 24px;
    min-height: 100%;
    background: #fff;
  }
}
@primary-color: #005f67;@layout-header-background: #005f67;